const environments = {
    localhost: 'localhost',
    dev: 'dev',
    test: 'test',
    prod: 'prod',
    qa: 'test',
    ist: 'ist',
    sit: 'sit',
};
const getCurrentEnv = (host) => {
    const regex = new RegExp('dev|ist|sit|test|qa|localhost');
    const results = regex.exec(host);
    return results ? results[0] : null;
};
export const getEnvironment = (host) => {
    const parsedHostname = getCurrentEnv(host);
    return parsedHostname ? environments[parsedHostname] : environments.prod;
};
