import { start, registerApplication, } from 'single-spa';
// eslint-disable-next-line unused-imports/no-unused-imports
import { loadRemoteComponent } from './remoteLoader';
// For Single-Spa-Layout instruction, see README
//MDS import for host
import '@mds/resources-web/dist/tokens/foundation/MdsFoundation-cpo.css';
import '@mds/resources-web/dist/tokens/components/MdsComponents-cpo.css';
import '@mds/resources-web/dist/grid-system/mds-grid-system.css';
import '@mds/resources-web/dist/typography/mds-typography.css';
import '@mds/resources-web/dist/utils/mds-utilities.css';
import '@mds/chase-web-icons/dist/iconfont/mds-chase-icons.css';
const apps = [
    {
        name: 'paymentPortalUiRemote',
        app: () => loadRemoteComponent('paymentPortalUiRemote', './App'),
        activeWhen: '/',
        customProps: {
            domElement: document.getElementById('root'),
        },
    },
];
apps.forEach(registerApplication);
start();
