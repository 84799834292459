import { config } from './remotes/config';
const defaultApp = {
    mount: () => {
        return Promise.resolve();
    },
    bootstrap: () => {
        return Promise.resolve();
    },
    unmount: () => {
        return Promise.resolve();
    },
};
function loadScript(scope) {
    return new Promise((resolve, reject) => {
        const element = document.createElement('script');
        element.src = config[scope].remoteUrl;
        element.type = 'text/javascript';
        element.async = true;
        element.onload = () => resolve();
        element.onerror = () => reject();
        const htmlElement = document.querySelector('head');
        htmlElement && htmlElement.appendChild(element);
    });
}
async function loadComponent(scope, module) {
    await __webpack_init_sharing__('default');
    const container = window[scope];
    await container.init(__webpack_share_scopes__.default);
    const factory = await container.get(module);
    const Module = factory();
    return Module;
}
async function loadRemoteComponent(scope, module) {
    try {
        await loadScript(scope);
        const Application = await loadComponent(scope, module).catch(() => console.log('DEBUGGING Remote Component Load Failed!'));
        return Application;
    }
    catch (error) {
        console.log(`Failed to load ${scope}`);
        return defaultApp;
    }
}
export { loadRemoteComponent };
